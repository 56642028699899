<template>
  <div class="row">
    <!-- begin::Overview -->
    <div class="col-12">
      <div class="card card-custom">
        <!-- begin::Header -->
        <div class="card-header flex-wrap">
          <div class="card-title">
            <h3 class="card-label">
              Sunbed errors
              <span class="text-muted pt-2 font-size-sm d-block">Errors of sunbeds that belong to this company.</span>
            </h3>
          </div>

          <div class="card-toolbar w-100 w-md-auto">
            <div class="col-12 col-md">
              <overview-filters
                class="btn-block w-100 w-md-150px"

                @apply="onFilters"
              />
            </div>

            <div class="col-12 col-md">
              <b-dropdown
                ref="exportDropdown"
                variant="primary"
                right
                no-caret
                no-flip
                lazy
                menu-class="dropdown-menu-md"
                class="w-100 w-md-150px"
              >
                <!-- begin::Button -->
                <template v-slot:button-content>
                  <i>
                    <font-awesome-icon :icon="['fas', 'file-export']" />
                  </i>
                  Export
                </template>
                <!-- end::Button -->

                <!-- begin::Dropdown items -->
                <template v-slot:default>
                  <ul class="navi flex-column navi-hover py-2">
                    <li class="navi-header font-weight-bolder text-uppercase font-size-xs text-primary pb-2">
                      Choose an action:
                    </li>

                    <li class="navi-item">
                      <a class="navi-link cursor-pointer" @click.prevent="exportResults">
                        <span class="navi-icon">
                          <i>
                            <font-awesome-icon :icon="['fas', 'file-archive']" class="w-15px h-15px" />
                          </i>
                        </span>
                        <span class="navi-text">Full log export</span>
                      </a>
                    </li>

                    <li class="navi-item">
                      <a class="navi-link cursor-pointer" @click.prevent="exportSummary">
                        <span class="navi-icon">
                          <i>
                            <font-awesome-icon :icon="['fas', 'clipboard-list']" class="w-15px h-15px" />
                          </i>
                        </span>
                        <span class="navi-text">Summary</span>
                      </a>
                    </li>
                  </ul>
                </template>
                <!-- end::Dropdown items -->
              </b-dropdown>
            </div>
          </div>
        </div>
        <!-- end::Header -->

        <!-- begin::Body -->
        <div class="card-body">
          <!-- begin::Datatable -->
          <datatable
            ref="datatable"
            :url="datatable.url"
            :fields="datatable.fields"
            :filters="datatable.filters"
            :search-fields="datatable.searchFields"
            :on-pagination-data="onPaginationData"
            :http-fetch="httpFetch"

            :transform="transform"
          >
            <!-- begin::Critical -->
            <div slot="critical" slot-scope="props">
              <span
                :class="[
                  'label w-100 label-inline label-xl label-rounded py-5',
                  (props.rowData.error.critical === true ? 'label-light-danger' : 'label-light-warning')
                ]"
              >
                {{ props.rowData.error.critical === true ? 'Critical' : 'Non-critical' }}
              </span>
            </div>
            <!-- end::Critical -->

            <!-- begin::Actions -->
            <template slot="actions" slot-scope="props">
              <b-dropdown
                ref="actions-dropdown"
                variant="clean btn-sm btn-icon"
                class="dropdown-inline mr-2"
                right
                lazy
                no-caret
                boundary="window"
                menu-class="dropdown-menu-md"
              >
                <template v-slot:button-content>
                  <span class="svg-icon">
                    <font-awesome-icon :icon="['fas', 'cog']" />
                  </span>
                </template>

                <template v-slot:default>
                  <ul class="navi flex-column navi-hover py-2">
                    <li class="navi-header font-weight-bolder text-uppercase font-size-xs text-primary pb-2">
                      Choose an action:
                    </li>

                    <li class="navi-separator my-0" />

                    <template>
                      <li class="navi-item">
                        <a class="navi-link cursor-pointer" @click.prevent="downloadLogFile(props.rowData, { query: { format: 'csv' } })">
                          <span class="navi-icon">
                            <i>
                              <font-awesome-icon :icon="['fas', 'file-csv']" class="w-15px h-15px" />
                            </i>
                          </span>
                          <span class="navi-text">Download CSV</span>
                        </a>
                      </li>

                      <li class="navi-item">
                        <a class="navi-link cursor-pointer" @click.prevent="downloadLogFile(props.rowData, { query: { format: 'json' } })">
                          <span class="navi-icon">
                            <i>
                              <font-awesome-icon :icon="['fas', 'file-code']" class="w-15px h-15px" />
                            </i>
                          </span>
                          <span class="navi-text">Download JSON</span>
                        </a>
                      </li>
                    </template>
                  </ul>
                </template>
              </b-dropdown>
            </template>
            <!-- end::Actions -->
          </datatable>
          <!-- end::Datatable -->

          <!-- begin::Datatable footer -->
          <div class="datatable datatable-default">
            <div class="datatable-pager datatable-paging-loaded">
              <datatable-pagination
                ref="pagination"
                @vuetable-pagination:change-page="onChangePage"
              />

              <datatable-pagination-info
                ref="pagination.info"
                @vuetable-paginationinfo:change-rowcount="onChangeRowCount"
              />
            </div>
          </div>
          <!-- end::Datatable footer -->
        </div>
        <!-- end::Body -->
      </div>
    </div>
    <!-- end::Overview -->
  </div>
</template>

<script>
import moment from 'moment-timezone';

import { DatatableMixin } from '@vedicium/metronic-vue';
import overviewFilters from '@/components/pages/statistics/sunbeds/errors/components/overview-filters.vue';

import Equipment from '@/libs/classes/equipment';
import LuxuraLogError from '@/libs/classes/luxura_log.error';

export default {
  mixins: [DatatableMixin],
  components: {
    overviewFilters,
  },
  data () {
    return {
      datatable: {
        url: `${Equipment.uri}/logs`,

        fields: [{
          name: 'critical',
          title: 'Type',
          sortField: 'error.critical',
          titleClass: 'w-100px',
          dataClass: 'w-100px',
        }, {
          name: 'log_date',
          title: 'Date',
          sortField: 'log_date',
          titleClass: 'w-150px',
          dataClass: 'w-150px',
          formatter: (value) => moment.utc(value).format('LLL'),
        }, {
          name: 'error.id',
          title: 'Error ID',
          sortField: 'error.id',
          titleClass: 'w-100px',
          dataClass: 'w-100px',
        }, {
          name: 'error.id',
          title: 'Error description',
          titleClass: 'w-300px',
          dataClass: 'w-300px',
          formatter: (value) => this.$tu(`luxura_logs.error.errors.${value}.description`) || `Error ${value}`,
        }, {
          name: 'log_id',
          title: 'Log',
          sortField: 'log_id',
          titleClass: 'w-100px',
          dataClass: 'w-100px',
          formatter (value) {
            return `#${value}`;
          },
        }, {
          name: 'controller_serial',
          title: 'Controller',
          sortField: 'controller_serial',
          titleClass: 'w-150px',
          dataClass: 'w-150px',
        }, {
          name: 'equipment.serial',
          title: 'Serial',
          titleClass: 'w-150px',
          dataClass: 'w-150px',
        }, {
          name: 'equipment.model',
          title: 'Model',
          titleClass: 'w-100px',
          dataClass: 'w-100px',
          formatter: (value) => this.$tu(`sunbeds.models.${value}.name`) || value,
        }, {
          name: 'equipment.location',
          title: 'Location',
          titleClass: 'w-200px',
          dataClass: 'w-200px',
        }, {
          name: 'actions',
          title: 'Actions',
          titleClass: 'w-100px',
          dataClass: 'w-100px',
        }],
        searchFields: [],

        sortOrder: [{
          field: 'log_date',
          direction: 'desc',
        }],

        filters: {
          type: 'error',
          'error.status': 3,
        },
      },

      equipment: new Map(),
    };
  },

  mounted () {
    this.$metronic.breadcrumbs.setBreadcrumbs([{ title: 'Statistics' }, { title: 'Sunbeds' }, { title: 'Errors' }]);
  },

  methods: {
    async httpFetch (apiUrl, httpOptions) {
      const requestOptions = await this.$refs.datatable.setRequestOptions(httpOptions);

      // Gather normal data
      const data = await this.$ws.get(apiUrl, {
        query: {
          ...requestOptions.params,
          format: 'datatable',
        },
      });

      // Gather equipment data
      const equipmentGuids = data.data.map((row) => row.equipment_guid, []).filter((row, idx, self) => self.indexOf(row) === idx, []).filter((row) => Array.from(this.equipment.keys()).includes(row) === false, []);
      if (equipmentGuids) {
        const equipmentData = await this.$ws.get(Equipment.uri, {
          query: {
            'filter[_meta.guid]': equipmentGuids,
          },
        });

        // Only save the data we need in the equipment map
        equipmentData.map((row) => new Equipment().merge(row), []).forEach((row) => {
          this.equipment.set(row._meta.guid, {
            serial: row.serial,
            model: row.model,
            location: row.location.description,
          });
        });
      }

      return { data };
    },

    transform (data) {
      const response = { ...data };
      if (response.data && Array.isArray(response.data)) {
        response.data = response.data.map((row) => {
          const log = new LuxuraLogError().merge(row);

          // Add equipment data
          log.equipment = this.equipment.get(log.equipment_guid);

          return log;
        });
      }

      return response;
    },

    async onFilters (filters = null) {
      if (!filters) {
        return;
      }

      // Copy current filters
      const datatableFilters = { ...this.datatable.filters };

      // Loop through every known status
      ['type', 'error.id', 'log_date'].forEach((filter) => {
        const value = filters[(filter)];

        switch (filter) {
          case 'type': {
            if (!value) {
              datatableFilters['error.critical'] = null;
            } else if (value.toLowerCase() === 'critical') {
              datatableFilters['error.critical'] = true;
            } else if (value.toLowerCase() === 'non-critical') {
              datatableFilters['error.critical'] = false;
            }
            break;
          }

          case 'log_date': {
            if (!value || !value.start || !value.end) {
              datatableFilters['log_date|lte'] = null;
              datatableFilters['log_date|gte'] = null;
            } else {
              datatableFilters['log_date|lte'] = value.end.getTime();
              datatableFilters['log_date|gte'] = value.start.getTime();
            }
            break;
          }

          default: {
            datatableFilters[(filter)] = value;
            break;
          }
        }
      });

      // Delete the equipment guid filter
      delete datatableFilters['equipment_guid|eq'];

      // Apply equipment filter if necessary
      if (Object.entries(filters).some(([filter, value]) => filter.startsWith('equipment.') && !!value)) {
        try {
          this.$refs.datatable.setLoading(true);
          const equipment = await this.applyEquipmentFilters(filters);
          if (equipment !== null) {
            datatableFilters['equipment_guid|eq'] = equipment.map((row) => row._meta.guid, []).join(',');
          }
        } finally {
          this.$refs.datatable.setLoading(false);
        }
      }

      // Remove 'null' values
      Object.entries(datatableFilters).forEach(([key, value]) => {
        if (value === undefined || value === null) {
          delete datatableFilters[key];
        }
      });

      // Apply filters
      this.$set(this.datatable, 'filters', datatableFilters);
      await this.$nextTick();

      if (this.$refs.datatable.$refs.vuetable.currentPage !== 1) {
        this.$refs.datatable.$refs.vuetable.gotoPage(1);
      } else {
        this.$refs.datatable.reload();
      }
    },
    async applyEquipmentFilters (filters = null) {
      if (!filters) {
        return null;
      }

      // Apply equipment filters
      const equipmentFilters = {};
      ['equipment.model', 'equipment.company_guids'].forEach((filter) => {
        const value = filters[(filter)];

        switch (filter) {
          default: {
            equipmentFilters[(filter.replace('equipment.', ''))] = value;
            break;
          }
        }
      });

      const query = {};
      // eslint-disable-next-line no-return-assign
      Object.entries(equipmentFilters).filter(([, value]) => value !== undefined && value !== null, []).forEach(([key, value]) => query[`filter[${key}]`] = value);

      return Equipment.getAllResults({ query });
    },

    async downloadLogFile (log = null, options = {}) {
      if (!log || log instanceof LuxuraLogError === false) {
        return;
      }

      this.$refs.datatable.setLoading(true);
      try {
        await log.download(options);
      } catch (e) {
        this.$errors.handle(e, { ui_element: true });
        console.error(e);
        return;
      } finally {
        this.$refs.datatable.setLoading(false);
      }
    },

    async exportResults () {
      this.$refs.exportDropdown.hide();

      const requestOptions = {
        query: {},
      };

      // Add filters
      Object.entries(this.datatable.filters).forEach(([field, value]) => {
        requestOptions.query[`filter[${field}]`] = value;
      });

      this.$refs.datatable.setLoading(true);
      try {
        await this.$ws.post(`${Equipment.uri}/logs/export`, requestOptions);

        // Inform user what's going to happen
        this.$metronic.eventhub.emit('modals:layout:information:open', {
          title: 'Export initiated',

          message: 'We are preparing your download. Feel free to continue using MyLuxura in the mean time. <br />When the requested file is ready you will receive an email with further instructions.',
        });
      } catch (e) {
        this.$errors.handle(e, { ui_element: true });
        console.error(e);
      } finally {
        this.$refs.datatable.setLoading(false);
      }
    },
    async exportSummary () {
      this.$refs.exportDropdown.hide();

      const requestOptions = {
        query: {},
      };

      // Add filters
      Object.entries(this.datatable.filters).forEach(([field, value]) => {
        requestOptions.query[`filter[${field}]`] = value;
      });

      this.$refs.datatable.setLoading(true);
      try {
        await this.$ws.post(`${Equipment.uri}/logs/error/export/summary`, requestOptions);

        // Inform user what's going to happen
        this.$metronic.eventhub.emit('modals:layout:information:open', {
          title: 'Export initiated',

          message: 'We are preparing your download. Feel free to continue using MyLuxura in the mean time. <br />When the requested file is ready you will receive an email with further instructions.',
        });
      } catch (e) {
        this.$errors.handle(e, { ui_element: true });
        console.error(e);
      } finally {
        this.$refs.datatable.setLoading(false);
      }
    },
  },
};
</script>
